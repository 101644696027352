import React from "react";
import Layout from "../../components/Layout";
import { Container } from "@chakra-ui/layout";
import PageHeadingSection from "../../components/PageHeadingSection";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Card from "../../components/Card";
import { splittedUID } from "../../utils/sortPages";
import { standardToName } from "../../utils/standardToName";
import Seo from "../../components/SEO";
import Bgimage2 from "../../images/bgimage2.png";
import ContactForm from "../../components/ContactForm";

function compareFunc(a, b) {
  return a.standard - b.standard;
}

const LeadAuditor = ({ data: QueryResult }) => {
  // Data by Accrediation Body including splittedUID
  const data = {
    "cqi-irca": [],
    "exemplar-global": [],
  };

  // Cards to render by Accrediation Body
  const cards = {
    "cqi-irca-cards": [],
    "exemplar-global-cards": [],
  };

  const AB_IMAGES = [QueryResult.cqi_irca.nodes[0], QueryResult.eg.nodes[0]];

  // Splitting UID and segregation of data by AB
  QueryResult.allPrismicTrainings.nodes.forEach((node) => {
    const { catagory, ab, standard, slug } = splittedUID(node.uid);
    data[ab].push({ ...node, standard, ab, catagory, slug });
  });

  for (const [ab] of Object.entries(data)) {
    // Sort
    data[ab].sort(compareFunc);

    data[ab].forEach(({ data, slug, ab, standard }) => {
      cards[ab + "-cards"].push(
        <Card
          title={data.title.text}
          path={`${ab}/${slug}`}
          imgData={data.featured_image.gatsbyImageData}
          name={standardToName(standard)}
          key={standard}
        />
      );
    });
  }

  return (
    <Layout>
      <Seo
        title={`Lead Auditor Trainings | ${QueryResult.site.siteMetadata.title}`}
      />
      <Box
        sx={{
          background: `url(${Bgimage2}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <Container maxW="7xl" py={5}>
          <Box
            d="flex"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Stack
              align={"center"}
              flex={1}
              py={{ base: 12, md: 16 }}
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                flex={1}
                justify={"center"}
                align={"center"}
                position={"relative"}
                w={"full"}
              >
                <Box flex={1} textAlign={{ base: "center", md: "left" }}>
                  <Heading as="h1" size="2xl" color={"#ffffff"}>
                    Lead Auditor Training
                  </Heading>
                  <Box
                    mx={{ base: "auto", md: "0" }}
                    sx={{
                      marginTop: "1.25rem",
                      marginBottom: "1.25rem",
                      width: "8rem",
                      height: "0.25rem",
                      backgroundColor: "#29a6ff",
                    }}
                  ></Box>
                  <Heading as="h6" size="md" mt={2} color="#ffffff">
                    Virtual Instructor Led Learning <br /> Flexible Timing{" "}
                    <br /> 15+ Years of experience <br /> Certified 12000+
                    Delegates
                  </Heading>
                </Box>
              </Flex>
            </Stack>
            <Box flex={1}>
              <Box
                maxW={"400px"}
                mx={"auto"}
                sx={{
                  "& > *": {
                    width: "100%",
                  },
                }}
              >
                <div id="contact-us"></div>
                <ContactForm />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <PageHeadingSection title={"Lead Auditor Trainings"} /> */}
      <Container maxW="7xl" py={5}>
        <Tabs variant="line" colorScheme="white" align="center">
          <TabList>
            {AB_IMAGES.map((img, i) => (
              <Tab mx={3} key={i}>
                <GatsbyImage image={getImage(img)} />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex wrap={"wrap"} justifyContent={{ base: "center" }}>
                {cards["cqi-irca-cards"]}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex wrap={"wrap"} justifyContent={{ base: "center" }}>
                {cards["exemplar-global-cards"]}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query CQIandEGImage {
    cqi_irca: allFile(
      filter: {
        relativeDirectory: { eq: "accrediationLogo" }
        name: { eq: "cqi irca" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }

    eg: allFile(
      filter: {
        relativeDirectory: { eq: "accrediationLogo" }
        name: { eq: "eg" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }

    allPrismicTrainings(filter: { tags: { in: "la" } }) {
      nodes {
        uid
        data {
          featured_image {
            gatsbyImageData
          }
          title {
            text
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default LeadAuditor;
